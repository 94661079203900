<template>
  <div>
    <!-- <div class="container">
      <div class="category__panel">
        <CategoryCard v-for="i in [0,1,2,3,4,5]" :key="i" class="item" :class="{'tail' : i % 3 == 2}"  @on-click="onCategoryClick"/>
      </div>
    </div> -->
    <div class="container">
      <Button class="autoplay-btn" type="default" style="margin-left:10px" @click="autoplay = !autoplay">{{ autoplay ? '暂停' : '播放' }}</Button>
      <Carousel class="banner" loop :autoplay="autoplay" :autoplay-speed="5000" arrow="always">
        <CarouselItem class="banner-item" v-for="item in banners" :key="item.id">
          <img class="banner-img" :src="item.src" />
          <div v-if="item.description" class="desc">{{item.description}}</div>
        </CarouselItem>
      </Carousel>
      <!-- <div class="panel-title">供需</div>
      <div class="category__panel">
        <div class="item" v-for="(item, index) in demandGroups" :key="index">
          <div class="category-title">
            <div class="title">{{ item.category.name }}</div>
            <div class="more" @click="onViewMoreDemand(item.category.id)">更多</div>
          </div>
          <div class="data-list">
            <div class="data-item" v-for="(demand, demandIndex) in item.rows" :key="demandIndex" @click="onViewDemandDetail(demand.id)">
              <div class="title">{{ demand.real_name }} - {{ demand.title }}</div>
              <div class="create-date">{{ demand.create_date }}</div>
            </div>
          </div>
        </div>
      </div> -->
      <!-- <div class="panel-title">公布</div> -->
      <div class="category__panel">
        <div class="item full-line">
          <div class="category-title">
            <div class="title">公布</div>
            <div class="more" @click="onViewMorePublicity()">更多</div>
          </div>
          <div class="data-list">
            <div class="data-item" v-for="(demand, demandIndex) in publicities" :key="demandIndex" @click="onViewPublicityDetail(demand.id)">
              <div class="title">{{ demand.real_name }} - {{ demand.title }}</div>
              <div class="create-date">{{ demand.create_date }}</div>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="panel-title">资料</div>
      <div class="category__panel">
        <div class="item" v-for="(item, index) in dataGroups" :key="index">
          <div class="category-title">
            <div class="title">{{ item.category.name }}</div>
            <div class="more" @click="onViewMoreData(item.category.id)">更多</div>
          </div>
          <div class="data-list">
            <div class="data-item" v-for="(dataRecord, dataRecordIndex) in item.rows" :key="dataRecordIndex" @click="onViewDataRecordDetail(dataRecord.id)">
              <div class="title">{{ dataRecord.real_name }} - {{ dataRecord.title }}</div>
              <div class="create-date">{{ dataRecord.create_date }}</div>
            </div>
          </div>
        </div>
      </div> -->
    </div>

    <!-- 客服信息 -->
    <!-- <div class="pendant">
       <Poptip trigger="hover" placement="left">
        <div class="item">
          <img src="@/assets/images/icon_call.png" />
          <div class="label">联系电话</div>
        </div>
        <div class="poptip__bd" slot="content">联系电话：{{contactPhone}}</div>
      </Poptip>
    </div> -->
    <div class="footer">
      <div class="container">
        <div class="fotter-main">
          <a href="https://beian.miit.gov.cn" class="aq" target="_blank">备案号：蜀ICP备2021021561号</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import banner from '@/assets/banner.jpg'
// import CategoryCard from '@/components/CategoryCard.vue'
import { getHomeData } from '@/api/home'
import { getDataGroup } from '@/api/data.js'
import { getPublicityIndex } from '@/api/publicity.js'
import { getDemandGroup } from '@/api/demand.js'
import { getSystemSetting } from '@/api/setting'

export default {
  name: 'Home',
  components: {
    // CategoryCard
  },
  data() {
    return {
      banner,
      banners: null,
      autoplay: true,
      // contactPhone: null,
      demandGroups: [],
      publicities: [],
      dataGroups: []
    }
  },
  computed: {
    ...mapGetters(['cityId'])
  },
  mounted() {
    this.initData()
  },
  methods: {
    async initData() {
      try {
        const res = await getHomeData({city_id: this.cityId})
        this.banners = res.content.banner
        // this.contactPhone = res.content.contact_phone
        await this.getDataGroup()
        await this.getPublicityIndex()
        await this.getDemandGroup()
      } catch (error) {
        this.$Message.error(error.message)
      }
    },
    onCategoryClick() {
      this.$router.push({ name: 'template'})
    },
    async getDataGroup() {
      const res = await getDataGroup({city_id: this.cityId})
      this.dataGroups = res.content
      // console.log(this.dataGroups)
    },
    async getPublicityIndex() {
      const res = await getPublicityIndex({city_id: this.cityId})
      this.publicities = res.content.rows
      // console.log(this.dataGroups)
    },
    async getDemandGroup() {
      const res = await getDemandGroup({city_id: this.cityId})
      this.demandGroups = res.content
      // console.log(this.demandGroups)
    },
    onViewMoreDemand(demandCategoryId) {
      console.log(demandCategoryId)
      this.$router.push({path: '/demand', query: { demandCategoryId }})
    },
    onViewMorePublicity() {
      this.$router.push({path: '/publicity'})
    },
    onViewMoreData(dataCategoryId) {
      console.log(dataCategoryId)
      this.$router.push({path: '/data', query: { dataCategoryId }})
    },
    onViewDemandDetail(id) {
      const routeData = this.$router.resolve({ name: 'DemandDetail', query: {id} })
      window.open(routeData.href, '_blank')
    },
    onViewPublicityDetail(id) {
      const routeData = this.$router.resolve({ name: 'PublicityDetail', query: {id} })
      window.open(routeData.href, '_blank')
    },
    onViewDataRecordDetail(id) {
      const routeData = this.$router.resolve({ name: 'DataRecordDetail', query: {id} })
      window.open(routeData.href, '_blank')
    }
  }
}
</script>

<style lang="less" scoped>
.banner {
  position: relative;
  width: 100%;
  // height: 260px;

  margin-top: 10px;
  border-radius: 5px;
  overflow: hidden;
  height: 402.85px;

  /deep/.ivu-carousel-dots {
    button {
      background: #fff !important;
      box-shadow: 0 0 5px #bbb;
    }
  }
}

.banner-img {
  width: 100%;
  // height: 260px;
  object-fit: cover;

  height: 402.85px;
}
.banner-item {
  position: relative;
}
.desc {
  position: absolute;
  bottom: 0;
  width: 100%;
  // background-color: rgba(255, 255, 255, .7);
  // color: #000;
  background-color: rgba(0, 0, 0, .4);
  color: #fff;
  padding: 5px 10px;
  padding-bottom: 20px;
  font-size: 24px;
  word-break: break-all;

  text-indent: 2rem;
}

.container {
  margin: 60px auto;
  margin-top: 0;

  position: relative;
}

.autoplay-btn {
  position: absolute;
  top: 4px;
  // top: 367px;
  right: 24px;
  z-index: 9999;
}

.panel-title {
  position: relative;
  padding-left: 14px;
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 24px;

  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 7px;
    height: 100%;
    background-color: #36d;
    border-bottom-left-radius: 5px;
    border-top-right-radius: 5px;
  }
}
.category__panel {
  position: relative;
  display: flex;
  width: 100%;
  justify-content: flex-start;
  flex-flow: wrap;
  border:2px solid black;
  .item {
    position: relative;
    // width: 30%;
    width: 49%;
    height: 0;
    // padding-bottom: 30%;
    padding-bottom: 300px;
    // margin-bottom: calc(5%);
    // margin-right: calc(5%);
    margin-bottom: calc(2%);
    margin-right: calc(2%);
    box-shadow: 0 0 5px #ccc;
    border-radius: 5px;
    background-color: #fff;

    &.full-line {
      width: 100%;
      margin-right: 0;
    }
    &:nth-child(2n) {
      margin-right: 0;
    }
    .category-title {
      display: flex;
      padding: 10px 10px;
      border-bottom: 1px solid #e0e0e0;
      .title {
        flex: 1;
        font-size: 18px;
      }
      .more {
        padding: 5px 10px;
        cursor: pointer;
        &:hover {
          color: #000;
        }
      }
    }
    .data-list {

      .data-item {
        display: flex;
        padding: 10px 10px;
        border-bottom: 1px solid #e0e0e0;
        cursor: pointer;
        font-size: 18px;

        &:hover {
          color: #000;
        }
        &:last-child {
          border-bottom: 0;
        }
        .title {
          flex: 1;
        }
        .create-date {

        }
      }
    }
  }
  .tail {
    margin-right: 0;
  }
}

/* .pendant {
  position: fixed;
  right: 20px;
  bottom: 170px;
  z-index: 1001;
  .item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 70px;
    height: 70px;
    background-color: #4183c4;
    border-radius: 5px;
    cursor: pointer;
    img {
      width: 28px;
      height: 28px;
    }
    .label {
      margin-top: 5px;
      color: #fff;
    }
  }
}

.poptip__bd {
  padding: 15px;
  font-size: 16px;
} */
.footer {
  background-color: rgb(94, 94, 94);
  .container {
    margin-bottom: 0;
    margin-top: 0;
  }
  .fotter-main {
     padding: 30px;

    .aq {
      color: #fff;
    }
  }
}
</style>
